body {
}

.recharts-cartesian-axis-tick,
.recharts-legend-item-text {
  font-size: 12px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #636463;
}
.recharts-legend-item-text {
  font-size: 9px;
}
.recharts-legend-wrapper {
  top: 0px;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.scatter-chart .recharts-xAxis .recharts-cartesian-axis-tick:first-child line {
  stroke: none;
}
.scatter-chart .recharts-xAxis .recharts-cartesian-axis-tick:first-child text {
  font-size: 0;
}
